<template>
  <div class="col-span-5 lg:col-span-4 md:grid md:grid-cols-4 items-center flex justify-center">
    <div class="col-start-2 col-span-2">
      <div class="w-full">
        <font-awesome-icon icon="fa-solid fa-lock"
          class="bg-primary text-white w-12 h-12 mx-auto flex rounded-full p-8 mt-4" />
        <div class="mt-4 font-bold text-lg text-center">
          Change PIN Balance
        </div>
        <ValidationObserver v-slot="{ invalid }" class="block mt-8">
          <ValidationProvider name="current PIN" :rules="{ required: true, regex: /^[0-9]+$/, length:6 }">
            <div class="input-field-label">Current PIN</div>
            <div class="flex">
              <font-awesome-icon class="input-field-icon" icon="fa-solid fa-lock" />
              <input v-model="form.currentPin" :type="showPin.current ? 'text' : 'password'" placeholder="Current PIN" pattern="\d*"
                class="input-field" autocomplete="new-password" @keypress="isNumber($event)" maxlength="6"
                @input="resetValidate" ref="current" />
              <div v-on:click="showPin.current = !showPin.current"
                class="cursor-pointer text-primary flex items-center">
                <font-awesome-icon v-if="showPin.current" icon="fa-solid fa-eye" />
                <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
              </div>
            </div>
            <small class="text-red-1 text-xs italic" v-if="form.validate.current">*Current PIN is required</small>
            <small class="text-red-1 text-xs italic" v-if="form.validate.current_six">*Current PIN must have 6
              digit</small>
          </ValidationProvider>
          <ValidationProvider class="block mt-6" name="new PIN" :rules="{
            required: true,
            regex: /^[0-9]+$/,
            length: 6,
            confirmed: { target: 'confirmation' },
          }">
            <div class="input-field-label">New PIN</div>
            <div class="flex">
              <font-awesome-icon class="input-field-icon" icon="fa-solid fa-lock" />
              <input v-model="form.newPin" :type="showPin.new ? 'text' : 'password'" placeholder="New PIN" pattern="\d*"
                class="input-field" autocomplete="new-password" @keypress="isNumber($event)" maxlength="6"
                @input="resetValidate" ref="new" />
              <div v-on:click="showPin.new = !showPin.new" class="cursor-pointer text-primary flex items-center">
                <font-awesome-icon v-if="showPin.new" icon="fa-solid fa-eye" />
                <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
              </div>
            </div>
            <small class="text-red-1 text-xs italic" v-if="form.validate.new">*New PIN is required</small>
            <small class="text-red-1 text-xs italic" v-if="form.validate.new_six">*New PIN must have 6 digit</small>
          </ValidationProvider>
          <ValidationProvider class="block mt-6" name="PIN confirmation" :rules="{ required: true, regex: /^[0-9]+$/, length: 6 }"
            vid="confirmation">
            <div class="input-field-label">Confirm New PIN</div>
            <div class="flex">
              <font-awesome-icon class="input-field-icon" icon="fa-solid fa-lock" />
              <input v-model="form.confirmationPin" :type="showPin.confirmation ? 'text' : 'password'" pattern="\d*"
                placeholder="Confirm New PIN" class="input-field" autocomplete="new-password"
                @keypress="isNumber($event)" maxlength="6" @input="resetValidate" ref="conf" />
              <div v-on:click="showPin.confirmation = !showPin.confirmation"
                class="cursor-pointer text-primary flex items-center">
                <font-awesome-icon v-if="showPin.confirmation" icon="fa-solid fa-eye" />
                <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
              </div>
            </div>
            <small class="text-red-1 text-xs italic" v-if="form.validate.confirmation">*Confirmation PIN is
              required</small>
            <small class="text-red-1 text-xs italic" v-if="form.validate.conf_six">*Confirmation PIN must have 6
              digit</small>
            <small class="text-red-1 text-xs italic" v-if="form.validate.conf_new">*Confirmation PIN doesn't match with
              New PIN</small>
          </ValidationProvider>
          <div class="flex justify-between mt-1 text-sm font-bold">
            <span>NB: PIN only numeric</span>
          </div>
          <div class="flex justify-end mt-3">
            <div @click="$router.push({ name: 'ForgotPin' })" class="cursor-pointer font-bold">
              Forgot Balance PIN ?
            </div>
          </div>
          <div class="flex justify-between mt-6">
            <button @click="resetForm" class="button-cancel">Cancel</button>
            <button @click="requestOtp" class="button-submit">
              Update
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <modal-otp ref="modalOTPRef"></modal-otp>
  </div>
</template>

<script>
export default {
  name: "Reset",
  components: {
    ModalOtp: () => import('@/components/layout/ModalOtp.vue'),
  },
  data() {
    return {
      form: {
        currentPin: '',
        newPin: '',
        confirmationPin: '',
        validate: {
          current: false,
          current_six: false,
          new: false,
          new_six: false,
          confirmation: false,
          conf_new: false,
          conf_six: false,
        },
      },
      showPin: {
        current: false,
        new: false,
        confirmation: false
      },
      otp: '',
      otpTimer: 0
    };
  },
  methods: {
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    resetForm() {
      this.$refs.current.value = null
      this.$refs.new.value = null
      this.$refs.conf.value = null
      this.form = {
        currentPin: null,
        newPin: null,
        confirmationPin: null
      };
    },
    resetValidate(Obj) {
      this.form.validate.current = false
      this.form.validate.current_six = false
      this.form.validate.new = false
      this.form.validate.new_six = false
      this.form.validate.confirmation = false
      this.form.validate.conf_new = false
      this.form.validate.conf_six = false
    },
    maxNumber(Obj){
      if (Obj.value.length > 6){
        Obj = Obj.slice(0,6)
      }
    },
    async requestOtp() {
      if (this.form.currentPin == '' || this.form.currentPin == null) {
        this.form.validate.current = true
        return false
      }
      if (this.form.currentPin.length != 6) {
        this.form.validate.current_six = true
        return false
      }
      if (this.form.newPin == '' || this.form.newPin == null) {
        this.form.validate.new = true
        return false
      }
      if (this.form.newPin.length != 6) {
        this.form.validate.new_six = true
        return false
      }
      if (this.form.confirmationPin == '' || this.form.confirmationPin == null) {
        this.form.validate.confirmation = true
        return false
      }
      if (this.form.confirmationPin != this.form.newPin) {
        this.form.validate.conf_new = true
        return false
      }

      this.$store.commit('setOverlayLoading', true);

      const checkData = new FormData();
      checkData.append("txtTelepon", this.formattedPhone);

      var diff = 0;
      let otpResponse;
      try {
        otpResponse = await this.$http.post(`${this.$apiTripweWebV2}/users/update-pin/validate`, 
          {
            'old_pin': this.form.currentPin,
            'new_pin': this.form.newPin,
            'confirm_new_pin': this.form.confirmationPin,
          });

        if (otpResponse.data.status != 200 && otpResponse.data.status != 403) {
          throw otpResponse.data.message;
        }
        if(otpResponse.data.status == 403){
          this.$toasted.global.error(otpResponse.data.message);
        }else{
          this.$toasted.global.success(otpResponse.data.message);
        }
        var curTime = this.$moment();
        var nextReq = this.$moment(otpResponse.data.data.next_request_at);
        diff = nextReq.diff(curTime, 'seconds');
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit('setOverlayLoading', false);
      }
      if (otpResponse.data.status == 200 || otpResponse.data.status == 403) {
        const otp = await this.$refs.modalOTPRef.open({ button: "Submit", scope: "UPDATE_PIN", otpTimer: diff });
        if(otp != null){
          this.otp = otp;
          this.updatePin();
        }
      }

    },
    async updatePin() {
      this.$store.commit('setOverlayLoading', true);

      try {

        const response = await this.$http.post(
          `${this.$apiTripweWebV2}/users/update-pin`,
          {
            'old_pin': this.form.currentPin,
            'new_pin': this.form.newPin,
            'confirm_new_pin': this.form.confirmationPin,
            'otp': this.otp,
          }
        );

        if (response.data.status != '200') {
          this.$toasted.global.error(response.data.message);
        } else {
          this.$toasted.global.success('Your PIN has been updated');
        }
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.resetForm();

      this.$store.commit('setOverlayLoading', false);
    },

    setOtpTimer() {
      this.otpTimer = 300;
      this.startOtpTimerCountdown();
    },

    startOtpTimerCountdown() {
      if (this.otpTimer > 0) {
        setTimeout(() => {
          this.otpTimer -= 1;
          this.startOtpTimerCountdown();
        }, 1000);
      }
    },
  },
  created() {
    this.$store.commit('setBreadcrumbItems', [
      { text: 'Home', routeName: 'Home' },
      { text: 'Balance', routeName: 'TripwePay' },
      { text: 'Setting', routeName: 'Settings' }
    ]);
  },
  computed: {
    isInvalidOtp() {
      return this.otp.toString().length != 6;
    },
    otpTimerMinutes() {
      const val = Math.floor(this.otpTimer / 60);
      return val < 10 ? `0${val}` : val;
    },
    otpTimerSeconds() {
      const val = this.otpTimer - this.otpTimerMinutes * 60;
      return val < 10 ? `0${val}` : val;
    },
    sendOtpInCooldown() {
      return this.otpTimer > 0;
    }
  },
};
</script>

<style scoped>
input[type="password"] {
  -webkit-text-security: square;
}
</style>
